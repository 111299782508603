.searchbar{
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    background: var(--secondary-white);
    border-radius: 8px;
    border: 1px solid var(--neutral-mid-grey);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    width: 100%;
    height: min-content;

    position: relative;
}


.searchbar__container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.searchbar__container img{
    width: 20px;
    height: 20px;
}
.searchbar__container input{
    width: 100%;
    border: none;
    outline: none;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--neutral-dark-grey);
}

.searchbar .modal.search{
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0, 45px);
    cursor: pointer;
    height: 100%;
    min-height: 200px;
}