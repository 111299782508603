.navbar{
    height: inherit;
    border-right: 1px solid var(--border-grey);
    background: var(--secondary-white);
    padding: 32px 24px;
    min-width: 280px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.fixed-navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.navbar__container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    gap: 24px;
}

.navbar__nav{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    gap: 24px;
}

.navbar__searchBar{
    width: 100%;
}

.navbar__nav__items{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.navbar__nav__item{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    border-radius: 6px;
}

.navbar__nav__toggle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    align-self: stretch;
    width: 100%;

    border-radius: 6px;
    padding: 8px 12px;
    cursor: pointer;
}


.navbar__nav__item a,
.navbar__nav__toggle_row{
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 6px;
    background: transparent;
    text-decoration: none;
    font-family: Poppins, sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: var(--primary-sea);
    transition: all 0.2s ease-in-out;
}

.navbar__nav__toggle_row{
    padding: 0;
}
.navbar__nav__subitems{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    list-style: none;

    display: none;
    transition: all 0.3s ease-in-out;
}

.navbar__nav__subitems:hover{
    display: flex;
}

.navbar__nav__subitems.visible {
    display: flex;
}

.navbar__nav__subitems.hidden {
    display: none;
}

.navbar__nav__item .navbar__nav__toggle img{
    transform: rotate(0deg);
    transition: all 0.2s ease-in;
}

.navbar__nav__item.expanded .navbar__nav__toggle img{
    transform: rotate(-180deg);
}


.navbar__nav__subitem{
    padding-left: 34px;
    width: 100%;
    border-radius: 6px;
}



.navbar__nav__subitems a{
    display: flex;
    align-items: center;
    align-self: stretch;
    border-radius: 6px;
    background: transparent;
    text-decoration: none;
    font-family: Poppins, sans-serif;
    font-size: 15px;
    font-weight: 400;
    padding: 8px 12px;
    color: var(--primary-sea);
    transition: all 0.2s ease-in-out;
}

.navbar__nav__item > a:hover,
.navbar__nav__subitem:hover,
.navbar__nav__toggle:hover{
    background: var(--neutral-grey-50);
    transition: all 0.2s ease-in-out;
}


.navbar__nav__item.active > a,
.navbar__nav__subitem.active{
    background: var(--neutral-grey-50);
    transition: all 0.2s ease-in-out;
}

.navbar__user{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    gap: 12px;

    border-top: 1px solid var(--border-grey);
    padding-top: 24px;
}

.navbar__user__infos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    gap: 0px;
    max-width: 120px;
}

.navbar__user__avatar{
    display: flex;
    width: 45px;
    height: 45px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border-grey);
    background-color: var(--neutral-grey-200);
    border-radius: 50%;
    overflow: hidden;
}

.navbar__user__avatar img{
    width: 100%;
    object-fit: cover;
}

.navbar__nav__icon{
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
}

.navbar__nav__item .partners{
    background-image: var(--icon-partners);
}

.navbar__nav__item .company{
    background-image: var(--icon-company);
}

.navbar__nav__item .verificationIcon{
    background-image: var(--icon-verification);
}

.navbar__nav__item .support{
    background-image: var(--icon-support);
}

.navbar__nav__item .dashboard{
    background-image: var(--icon-dashboard);
}

.navbar__nav__item .analytics{
    background-image: var(--icon-analytics);
}

.navbar__nav__item .settings{
    background-image: var(--icon-settings);
}
.navbar__nav__item .users{
    background-image: var(--icon-users);
}
.navbar__nav__item .manager{
    background-image: var(--icon-manager);
}

.navbar__nav__item .booking{
    background-image: var(--icon-booking);
}

.navbar__nav__item .stripe{
    background-image: var(--icon-stripe);
}

.navbar__nav__item .bookings{
    background-image: var(--icon-bookings);
}

.navbar__user__actions{
    padding-left: 24px;
}
.navbar__user__actions .navbar__nav__icon{
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    cursor: pointer;
}

.navbar__user__actions .logout{
    background-image: var(--icon-logout);
}

.navbar__nav__booking_badge_absolute{

    background: var(--error-red-100);
    color: var(--secondary-white);
    border-radius: 50%;
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;

    width: 22px;
    height: 22px;


    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.navbar__partner{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    gap: 12px;
}

.navbar__partner__infos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    gap: 0px;
    max-width: 120px;
}

/* ------------- MEDIA QUERIES ------------- */
@media screen and (max-width: 991px){
    .sidebar_nav{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 60px;
        padding: 0;

        box-shadow: none;
        background: none;
    }

    .navbar.fixed-navbar{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 60px;
        padding: 0;

        box-shadow: none;
        background: none;
    }

    .navbar__container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        gap: 24px;
        height: 60px;
        width: 100%;
        background: #ffffff;
        box-shadow: 0 1px 3px var(--neutral-grey-200);
        z-index: 99;
        position: relative;
    }

    .hamburger{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 5px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .hamburger .line{
        width: 24px;
        height: 2.5px;
        background: var(--neutral-grey-500);
        border-radius: 2px;
    }

    .hamburger .line:nth-child(1){
        transform: translateY(0px);
    }

    .hamburger .line:nth-child(2){
        width: 18px;
    }

    /* Keyframe animations */
    @keyframes rotateFirstLine {
        0% { transform: translateY(0) rotate(0); }
        50% { transform: translateY(8px) rotate(0); }
        100% { transform: translateY(8px) rotate(45deg); }
    }

    @keyframes rotateThirdLine {
        0% { transform: translateY(0) rotate(0); }
        50% { transform: translateY(-8px) rotate(0); }
        100% { transform: translateY(-8px) rotate(-45deg); }
    }

    @keyframes fadeOutSecondLine {
        0% { opacity: 1; }
        50% { opacity: 0; }
        100% { opacity: 0; }
    }

    .hamburger.open .line:nth-child(1) {
        animation: rotateFirstLine 0.3s forwards;
    }

    .hamburger.open .line:nth-child(3) {
        animation: rotateThirdLine 0.3s forwards;
    }

    .hamburger.open .line:nth-child(2) {
        animation: fadeOutSecondLine 0.3s forwards;
    }

    /* Reverse animations for closing the menu */
    @keyframes rotateFirstLineReverse {
        0% { transform: translateY(8px) rotate(45deg); }
        100% { transform: translateY(0px) rotate(0); }
    }

    @keyframes rotateThirdLineReverse {
        0% { transform: translateY(-8px) rotate(-45deg); }
        100% { transform: translateY(0px) rotate(0); }
    }

    @keyframes fadeInSecondLine {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }

    .hamburger.close .line:nth-child(1) {
        animation: rotateFirstLineReverse 0.3s forwards;
    }

    .hamburger.close .line:nth-child(3) {
        animation: rotateThirdLineReverse 0.3s forwards;
    }

    .hamburger.close .line:nth-child(2) {
        animation: fadeInSecondLine 0.3s forwards;
    }


    .navbar__menu {
        position: fixed; /* Fixed position */
        top: 0;          /* Align top */
        right: 0;        /* Start from the right side */
        width: 100vw;    /* Full width */
        height: 100dvh;   /* Full height */
        background-color: #fff; /* Background color */
        box-shadow: -1px 0 5px rgba(0,0,0,0.1); /* Optional: adds shadow for better visibility */
        transform: translateX(100%); /* Start off-screen */
        transition: all 0.5s ease; /* Smooth transition for sliding in */
        z-index: 5;

    }

    /* Active state when menu is open */
    .navbar__menu.active {
        transform: translateX(0); /* Slide into view */
    }

    .navbar__menu__container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 24px;

        padding-top: 80px;
        height: 100%;
    }

    .navbar__nav{
        padding: 0 12px;
    }

    .navbar__bottom{
        width: 100%;
        gap: 12px;
        display: flex;
        flex-direction: column;
    }

    .navbar__user{
        border-top: 1px solid var(--neutral-grey-200);
        width: 100%;
        padding: 18px 24px;
        gap: 15px;
    }

    .navbar__user__infos{
        max-width: unset;
    }

}
