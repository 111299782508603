.main__container.login{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;

    width: 100%;
    height: 100%;
    overflow: hidden;
}

.main__container__image__login{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    gap: 54px;

    width: 55%;
    height: 100%;
    background: var(--neutral-grey-50);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    padding: 60px 0px;

    position: relative;
}

.main__container__section__login{
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.main__container__form__login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 35px;

    width: 450px;
    height: 100%;
    padding: 60px 40px 0 40px;
}

.main__container__form__col__title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
}

.main__container__quote{
    padding: 0 80px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.main__container__form__col{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    gap: 32px;

    width: 100%;
    height: 100%;
}

.main__container__form h2{
    text-align: center;
}

.form__login{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}

.main__container__image__login > img{
    width: 100%;
    min-width: 800px;
    height: auto;

    position: absolute;
    top: 330px;
    left: 50px;
}

.main__footer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 32px;
}

.main__footer__links{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}
.main__footer p{
    color: var(--neutral-grey-600);
}


.main__footer__links img{
    width: 14px;
    height: 14px;
}


/* MOBILE */
@media screen and (max-width: 991px){
    .main__container.login{
    }
    .main__container__image__login{
        display: none;
    }

    .main__container__section__login{
        width: 100%;
    }
    .main__container__form__login{
        width: 100%;
        padding: 60px 30px 0 30px;
    }
}