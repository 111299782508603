.policy{
    overflow: scroll;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    padding: 32px;
    background: var(--neutral-grey-50);
}

.text{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    background: var(--secondary-white);
    padding: 32px;
    max-width: 700px;
    border-radius: 20px;
}

.text .main__container__section{
    width: 100%;
}

.text .main__container__form{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.container__infos{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    text-align: left;
}

.container__infos ol{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    max-width: 90%;
}

.container__infos h1{
    text-align: center;
}

.container__infos li{
    font-size: 16px;
}

/* CARD */
.container__infos__cards{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    text-align: left;
}

.container__infos__cards__card{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    box-shadow: 0px 0px 3px 2px rgba(16, 24, 40, 0.02), 0px 0px 11px 2px rgba(16, 24, 40, 0.04);
    border-radius: 10px;
    padding: 20px;
}


/* FAQ */
.container__content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    text-align: left;
}

.container__infos_text{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    text-align: left;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--neutral-grey-200);

}

.container__content h2{
    font-size: 18px;
    font-weight: 600;
    text-align: left;
}