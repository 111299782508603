.header-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 24px;
}

.header-col .header-view > .icon-btn img{
    width: 15px;
}

.header-view{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.header_title{
    color: var(--typography-black);

    font-family: RedHatDisplay, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
}

.header-view__actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

/* ------------- MEDIA QUERIES ------------- */
@media screen and (max-width: 991px){
    .header-view{
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        gap: 24px;

        padding: 0 12px;
    }

    .header-view__actions{
        width: 100%;
    }

    .header-view__actions .btn{
        width: 100%;
        justify-content: center;
        padding: 16px 24px;
    }

    .booking__container__actions{
        padding: 0 12px;
    }
}