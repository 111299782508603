.main-activity__container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    height: fit-content;

    border-radius: 21px;
    padding: 26px 24px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);
}

.main-activity__container__row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.main-activity__container__buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}

.container__row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.header__container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.empty.stroke{
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    height: fit-content;
    width: 100%;
    max-width: fit-content;
    min-height: 50px;
    border: 1px solid var(--neutral-grey-300);
}