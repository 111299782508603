.container__date-picker{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
    padding: 0px;
    margin-top: 10px;
    min-width: 650px;

    border-radius: 12px;
    border: 1px solid var(--neutral-grey-200);
    background: var(--secondary-white);
}

.container__date-picker.single{
    margin-top: 0px;
    min-width: 250px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid var(--neutral-grey-200);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.date-picker__header{
    display: flex;
    padding: 15px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid var(--neutral-grey-200);
    background: var(--neutral-grey-50);
}

.date-picker__bottom{
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    border-top: 1px solid var(--neutral-grey-200);
}

.date-picker__selected-actions{
    display: flex;
    flex-direction: row;
    padding: 0px;
    align-items: flex-start;
    gap: 12px;

}

.date-picker__selected-dates{
    display: flex;
    flex-direction: row;
    padding: 0px;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.date-picker__selected-date{
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid var(--neutral-grey-200);
    background: var(--secondary-white, #FFF);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    color: var(--neutral-grey-700);

    /* Text md/Regular */
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.date-picker__selected-date.placeholder{
    color: var(--neutral-grey-300);
    font-weight: 300;
}

/* CALENDAR */
.date-picker__body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0px;
    padding: 0px;
    width: 100%;
}

.calendar__container{
    font-family: "Poppins", sans-serif;
    width: 100%;
    max-width: 350px;

    display: flex;
    padding: 20px  24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    margin: 0px;
}

.calendar__container.single{
    width: 100%;
    max-width: 100%;
}

.calendar__container:first-child{
    border-right: 1px solid var(--neutral-grey-200);
}

.calendar__container.single:first-child{
    border-right: 0px;
}

.calendar-navigation{
    display: flex;
    width: 100%;
    padding: 0px;
    justify-content: space-between;
    align-items: center;
}

.calendar-navigation img{
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.calendar-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
    width: 100%;
}

.month-name{
    /* Text md/Semibold */
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    color: #344054;
    text-align: center;
}

.weekdays {
    display: flex;
    width: 100%;
    padding: 0px;
    align-items: flex-start;
    gap: 0px;
}

.weekday {
    flex: 1;
    text-align: center;
    padding: 5px 0;
    /* Text md/Semibold */
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    color: var(--neutral-grey-600);
}

.days-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr); /* 7 days of the week */
    grid-row-gap:5px;
    width: 100% ;
}

.day {
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    border-radius: 4px;
    transition: background-color 0.3s;

    display: flex;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;

    color: var(--neutral-grey-500, #667085);

    /* Text sm/Regular */
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    border-radius: 100%;
}

.day:hover {
    background-color: #ffeba1;
}

.day.disabled:hover{
    background-color: none;
}

.day.other-month {
    color: var(--neutral-grey-300);
}

.day.other-month:hover,
.day.other-month.selected{
    color: var(--secondary-white);
}

.day.selected{
    background-color: var(--primary-yellow);
    border-radius: 0;
}
.day.selected-day{
    border: 1px solid var(--primary-yellow);
}

.day.hovered{
    background-color: #ffeba1;
    border-radius: 0;
}

.day.booked,
.day.booked-in-progress{
    background-color: var(--warning-yellow-200);
    color: var(--neutral-grey-500);
}

.day.in-progress,
.day.booked-in-progress{
    position: relative;
}
.day.in-progress::after,
.day.booked-in-progress::after{
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: var(--primary-yellow);
    position: absolute;
    bottom: 3px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
}

.day.selected.past,
.day.start-date.past,
.day.end-date.past{
    color: var(--neutral-grey-500);
    background-color: #ffeba1;
}

.day.hovered:last-child{
    border-radius: 0 100% 100% 0;
}

.day.start-date{
    background-color: var(--primary-yellow);
    border-radius: 100% 0 0 100%;
}

.day.end-date{
    background-color: var(--primary-yellow);
    border-radius: 0 100% 100% 0;
}

.day.excluded{
   padding: 0px;
    background: none;
    position: relative;
}

.day.excluded:after{
    /* little circle */
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: var(--primary-yellow);

    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
}

.day.past {
    color: var(--neutral-grey-300);
}

.day.past:hover {
  background: none;
}

.day.current-day {
    background: var(--neutral-grey-200);
    border: none!important;
    pointer-events: none; /* Prevent clicking on the current day */
    cursor: not-allowed;
}

.single .day.closed-day {
    background: var(--neutral-grey-200);
    border: none;
}

.single .day:hover {
    background: transparent;
    border: 1px solid var(--primary-yellow);
}

.single .day.current-day {
    border: 2px dashed var(--warning-yellow-700)!important;
    border-radius: 0;
    background: transparent;
}

.single .day.past-day {
    background: transparent;
    border: none;
    cursor: not-allowed;
    color: var(--neutral-grey-200);
}


.day.disabled {
    pointer-events: none;
    cursor: not-allowed;
}

.day .day__excluded{
    background-color: var(--secondary-white);
    color: var(--neutral-grey-500);
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 9px 10px;
}

.day.last-hovered{
    border-radius: 0 100% 100% 0;
}

.day.end-date.hovered{
    border-radius: 0;
}


.days-grid .day:nth-child(7n+1) { /* First element of each row */
    border-radius: 100% 0 0 100%!important;
}

.days-grid .day:nth-child(7n) { /* Last element of each row */
    border-radius: 0 100% 100% 0;
}

.days-grid.single .day:nth-child(7n+1) { /* First element of each row */
    border-radius: 100%!important;
}

.days-grid.single .day:nth-child(7n) { /* Last element of each row */
    border-radius: 100%;
}

.calendar__container button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 1.5em;
}

.calendar__container button:focus {
    outline: none;
}
