/*--- SUBCRIPTION CARD ---*/
.subscription__content{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 25px;
    width: 100%;
}

.subscription__plan{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    text-align: left;

    border-radius: 12px;
    border: 2px solid var(--neutral-grey-100);
    background: var(--secondary-white);
    box-shadow: 0px 22px 6px -7px rgba(16, 24, 40, 0.01), 0px 7px 9px -2px rgba(16, 24, 40, 0.02);
}

.subscription__plan__header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    padding: 23px 23px 23px 23px;

    border-bottom: 1.5px solid var(--neutral-grey-300);
}

.subscription__plan__header__infos{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.subscription__plan__title{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
}

.subscription__plan__discount{
    display: flex;
    padding: 6px 10px;
    align-items: center;
    border-radius: 15px;
    background-color: #27A740;

    color: var(--secondary-white);
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px; /* 142.857% */
}

.subscription__plan__price{
    font-family: 'RedHatDisplay', sans-serif;
    font-size: 45px;
    font-weight: 600;
}

.subscription__plan__header .is__secondary{
    margin-top: 20px;
    margin-bottom: 10px;
}

.subscription__content p.light{
    color: var(--neutral-grey-600);
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
}

.subscription__plan__content{
    padding: 13px 23px 29px 23px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
}

.subscription__plan__content__avantages{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    padding-bottom: 32px;
    border-bottom: 1.5px solid var(--neutral-grey-300);
}

.subscription__plan__content__avantages:last-child{
    border-bottom: none;
    padding-bottom: 0px;
}



.subscription__plan__list{
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
    list-style: none;
}

.subscription__plan__list li{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    color: var(--neutral-grey-500);
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px; /* 113.333% */
}

.subscription__plan__list li img{
    width: 22px;
    height: 22px;
    opacity: 0.8;
}

.subscription__plan.active{
    border: 2px solid var(--primary-yellow);
    background: var(--primary-yellow-50);
}

/*--- PAYEMENT CARD ---*/
.payment-methods__container__card{
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    border-radius: 12px;
    border: 1px solid var(--neutral-grey-200);
    background: var(--secondary-white);
}

.payment-methods__container__card.default{
    border: 1px solid var(--primary-yellow);
    background: var(--primary-yellow-50);
}

.payment-methods__container__card__infos{
    display: flex;
    padding: 0px;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
}

.payment-methods__container__card__infos__icon{
    display: flex;
    width: 60px;
    height: 42px;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
    border: 1px solid var(--neutral-grey-100);
    background: var(--secondary-white);
}

.payment-methods__container__card__infos__icon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.payment-methods__container__card__infos__text__actions{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    margin-top: 15px;
}



.payment-methods__container__card .text-12{
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    cursor: pointer;
}

.subscription__card_col{
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
}

.subscription__card__commission{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 30px;
}

.subscription__card__commission .form__row{
    max-width: 180px;
}
