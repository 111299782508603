/* HELP CARD */
.main-card__help{
    display: flex;
    padding: 50px 60px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;

    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);
    border-radius: 15px;
}

.main-card__help.grey{
    padding: 32px 0 0 0;
}

.main-card__help__contact{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
}
.main-card__help__subscription{
    display: flex;
    flex-direction: column;
    padding: 45px 16px ;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: #F9FAFB;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.main-card__help__subscription .main-offer__help__contact{
    margin-top: 30px;
}

.main-card__help p{
    max-width: 80%;
    text-align: center;
}

/* Red card */
.infored-card{
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    border-radius: 12px;
    border: 1px solid var(--error-red-300);
    background: var(--error-red-25);
}

.infored-card-column{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.infored-card-title{
    font-size: 16px;
    font-weight: 500;
    color: var(--error-red-700);
}

.infored-card-text{
    font-size: 14px;
    font-weight: 400;
    color: var(--error-red-700);
}


/* CARD PARTNER */
.partner-card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 12px;
    padding: 16px;

    width: 100%;


    border-radius: 12px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);
    max-width: 250px;

    cursor: pointer;

    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.partner-card:hover{
    border: 1px solid var(--primary-yellow);
    background: var(--warning-yellow-50);
}

.no-subscription{
    border: 1px solid var(--error-red-300);
    background: var(--error-red-25);
}

.warning{
    border: 1px solid var(--primary-yellow);
    background: var(--warning-yellow-25);

}

.partner-card__column{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.partner-card__row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.partner-card__name{
    font-size: 25px;
    font-weight: 600;
    font-family: 'RedHatDisplay', sans-serif;
    color: var(--neutral-grey-900);
}

.partner-card__location{
    font-size: 14px;
    font-weight: 500;
    color: var(--neutral-grey-700);
}

.partner-card__alert{
    font-size: 14px;
    font-weight: 500;
    color: var(--error-red-700);
    margin: 0;
}

.partner-card__warning{
    font-size: 14px;
    font-weight: 500;
    color: var(--warning-yellow-700);
    margin: 0;
}

/* ------------- MEDIA QUERIES ------------- */
@media screen and (max-width: 991px){


     .main-card__help{
        gap: 20px;
        padding: 12px;
    }
}


