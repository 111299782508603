.filterTable{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.filterTable__search{
    min-width: 250px;
}

.filterTable__table__filter_action{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;

    position: relative;
}

.filterTable__table{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: auto;
}

.filterTable__table__filter_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.filterTable__table__filter{
    position: relative;
}

.filterTable__table__filter_action > .icon-btn img {
    width: 18px;
}

.modal.filter{
    min-width: 250px;
    max-height: fit-content;
    bottom: 0;
    right: 0;
    transform: translate(-200px, 30px);
    gap: 15px;
}

.filter .modal__container__body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}
.filter .status_filter{
    cursor: pointer;
}
.filter .status_filter .tag{
    padding: 4px 15px;
}

.filter .modal__header__actions{
    cursor: pointer!important;
}