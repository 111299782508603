.settings-container{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid #EAECF0;
    background: var(--secondary-white);

    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.settings-section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}
.settings-section:not(:last-child){
    border-bottom: 1px solid #EAECF0;
    padding-bottom: 30px;
}

.settings-section__container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.isRow{
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.isRowGap{
    flex-direction: row;
    align-items: flex-start;
    gap: 50px;
}

.gap-25{
    gap: 25px;
}


.gap-10{
    gap: 10px;
}

/* ACCOUNT */
.settings-avatar{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: fit-content;
}

.settings-avatar__img{
    max-width: 400px;
    max-height: 300px;
    min-width: 300px;
    min-height: 220px;
    text-align: center;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
    border: 1px solid var(--neutral-grey-100)!important;
}

.settings-avatar__img.dash{
    border: 1px dashed var(--neutral-grey-300)!important;
}

.settings-avatar__img img{
    width: 100%;
    height: 100%;
    max-height: 250px;
    object-fit: cover;
    border-radius: 15px;
}

.settings-avatar__img .icon{
    width: 50px;
    height: 50px;
}

.settings-avatar__img .input__files__custom__text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.settings-avatar__img input[type="file"]{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.settings-form{
    width: 55%;
}

.settings-form form{
    gap: 20px;
}

.settings-form .form__row,
.settings-form .form__col,
.settings-form .form__row.column{
    align-self: stretch;
    width: 100%;
}

.settings-form .form__row.column > .form__col{
    width: 100%!important;
}
.settings-form .form__row.column > .form__col .form__input{
    width: inherit!important;
}

.settings-form .form__row__label{
    min-width: fit-content;
}

.settings-section__container__col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

}