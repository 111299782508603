.type-container{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid #EAECF0;
    background: var(--secondary-white);

    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.type-container__content{
    display: flex;
    flex-direction: column;
    gap: 28px;
    align-self: stretch;
}


.type__list{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.type__list_no-grid{
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
}

.type__list__item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    position: relative;

    border-radius: 12px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    overflow: hidden;
}

.type__list__item_simple{
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    position: relative;

    border-radius: 8px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.type__img{
    width: 100%;
    height: 150px;
    background: var(--neutral-grey-200);
}

.type__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.type__text{
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    background: var(--secondary-white);
}

.type__actions{
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.type__actions__delete,
.type__actions__edit{
    cursor: pointer;
    border-radius: 5px;
    padding: 3px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.type__actions__edit{
    border: 1px solid var(--warning-yellow-700);
    background: var(--warning-yellow-200);
}

.type__actions__delete:hover{
    background: var(--neutral-grey-100);
}

.type__actions img{
    width: 18px;
    height: 18px;
    cursor: pointer
}


.type__delete{
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 8px;
    border-radius: 50%;
    background: var(--neutral-grey-200);

    cursor: pointer;
    width: 17px;
    height: 17px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.type__delete img{
    width: 12px;
    height: 12px;
}
