.avatar__div{
    display: flex;
    width: 45px;
    height: 45px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border-grey);
    border-radius: 50%;
    overflow: hidden;

    font-weight: 400;
    font-size: 16px;
}


.avatar__div img{
    width: 100%;
    object-fit: cover;
}
