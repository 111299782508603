.dot-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    width: 30px;
}

.dot-container__items{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    width: 30px;
}

.dot{
    width: 3.5px;
    height: 3.5px;
    border-radius: 50%;
    background: var(--neutral-grey-400);
}

.dot.big{
    width: 4px;
    height: 4px;
}

.dot-container__content{
    position: relative;
}