.toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.toggle{
    position: relative;
}

.toggle-checkbox {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 9999;
}

.toggle-bg {
    display: flex;
    width: 45px;
    height: 25px;
    padding: 2px;
    justify-content: flex-end;
    align-items: center;
    border-radius: 12px;
    background: var(--neutral-grey-300);
    transition: all 0.2s ease-in-out;
}

.toggle-btn {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 3px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.toggle-circle {
    width: 100%;
    height: 100%;
    background-color: var(--secondary-white);
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.toggle-checkbox:checked + .toggle-bg .toggle-btn {
    left: 22px;
}

.toggle-checkbox:checked + .toggle-bg{
    background: var(--primary-yellow);
}
