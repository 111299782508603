.support form{
    gap: 15px;
}

.support .form__col{
    width: 100%;
}

.support .main-infos__container__col{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

/* ------------- MEDIA QUERIES ------------- */
@media screen and (max-width: 991px){

    .support .header-view:first-child {
        display: none;
    }

    .support .main-infos__container__col{
        gap: 20px;
        padding: 0 12px;
    }
}