.container-invoice{
    padding: 80px;

    border-radius: 5px;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    gap: 100px;
}

.logo{
    width: 180px;
}

.invoice-section{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    width: 100%;

    border: 1px solid #D0D5DD;
    border-radius: 12px;
    padding: 20px;
}

.infos-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;

    width: 100%;
}

.infos-col{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.infos-column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.infos-img{
    border-radius: 15px;
    overflow: hidden;
    max-height: 200px;
    display: flex;
}

.infos-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.separationLine{
    width: 100%;
    border: 1px solid #e0e0e0;
    margin: 10px 0;
}

.flex_end{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.invoice-footer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    width: 100%;

    border-top: 1px solid #D0D5DD;
    padding-top: 20px;
    margin-top: 50px;
}

.infos-grid{
    display: grid;
    grid-template-columns: 1fr 70px 1fr;
    gap: 0px;
}

.infos-line{
    width: 2px;
    height: 100%;
    background-color: var(--primary-sea);
}

.title-section{
    font-size: 17px;
    font-weight: 600;
    color: var(--primary-sea);
}

@media screen and (max-width: 991px){
    .container-invoice{
        padding: 24px;
        gap: 30px;

        max-width: 450px;
        width: 100%;
        margin: 0 auto;
    }

    .logo{
        width: 130px;
    }

    .infos-row{
        flex-wrap: wrap;
        gap: 5px;
    }

    .infos-col{
        gap: 10px;
    }

    .infos-column{
        gap: 20px;
    }

    .infos-column.paiements{
        gap: 10px;
    }

    .infos-content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;
    }

    .infos-grid{
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
    }

    .invoice-section{
        gap: 8px;
    }

    .invoice-section.paiements{
        gap: 32px;
    }


}