.tooltip{
    position: relative;
    cursor: pointer;

    width: 30px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

}

.tooltip__message{
    max-width: 320px;
    width: 320px;


    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    position: absolute;
    bottom: 45px;
    left: 50%;
    transform: translate(-50%, 10px);
    z-index: -1;
    opacity: 0;

    border-radius: 8px;
    background: var(--neutral-grey-900);
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

    transition: all 0.3s ease-in-out;
}

.last .tooltip__message{
    right: 0%!important;
    left: 100%!important;
    transform: translate(-100%,10px);
}

.tooltip__message--open{
    z-index: 100;
    opacity: 1;
    transform: translate(-50%, 0);
    transition: all 0.3s ease-in-out;
}

.tooltip__message p{
    margin: 0;
    color: var(--secondary-white);
}