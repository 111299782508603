.offer .header__container{
    display: flex;
    flex-direction: row!important;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    margin-bottom: 24px;
}

.offers_card{
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--neutral-grey-200);
    overflow: hidden;
    min-width: 220px;
}

.offers_card__row{
    display: flex;
    height: 40px;
    padding: 24px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-bottom: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}



.offers_card__row:first-child{
    background: var(--neutral-grey-200);
}

.offers_card__row:last-child{
    border-bottom: none;
}

.offers_card__row .text-20{
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
}

.day-time-slots{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px 12px 16px;
    gap: 78px;
    width:30vw;
}

.day-time-slots .form__row.column,
.day-time-slots .form__row.column > label,
.day-time-slots .form__row.column select{
    max-width: 150px;
    width: 150px;
    min-width: 150px;
}

.form__row__days-times{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 22px;
}
