a{
    text-decoration: none;
}
.btn{
    display: flex;
    padding: 10px 16px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    border-radius: 12px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border: 1px solid var(--neutral-grey-300);

    cursor: pointer;
    width: max-content;
    background: var(--secondary-white);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.btn-primary,
.is__primary{
    background: var(--primary-yellow);
    border: 1px solid var(--primary-yellow);
    color: var(--btn-color);
}

.btn p{
    color: var(--neutral-grey-700);
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.full{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--btn-color);
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}


.icon-btn p{
    font-weight: 500;
    width: max-content;
}

.icon-btn.full{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-btn img{
    width: 20px;
    height: 20px;
}

.red{
    background: var(--error-red-80);
    border: 1px solid var(--error-red-80);
}

.red_light{
    background: var(--error-red-50);
    border: 1px solid var(--error-red-100);
}

.red p{
    color: var(--error-red-600);
}

.btn.disabled{
    opacity: 0.5;
    cursor: not-allowed;
}

.black{
    background: var(--neutral-grey-700);
    border: 1px solid var(--neutral-grey-700);
    color: var(--secondary-white);
    padding: 12px 24px;
    border-radius: 10px;
}

.black p{
    color: var(--secondary-white);
    font-size: 14px;
}

.black img{
    width: 20px;
    height: 20px;
}
