/* bookingsSidebar.css */

.booking__sidebar__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, 0.5); /* Adds a semi-transparent overlay */
    z-index: 100; /* Ensures it sits on top of other content */

}

.booking__sidebar__background__overlay{
    width: 100vw;
    height: 100vh;
}

.booking__sidebar__section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: stretch;
    gap: 0;
    width: 100%;
    height: 100%;
    position: relative;
}

.booking__sidebar__container {
    width: 45vw;
    height: 100%;
    overflow: scroll;
    background: white;
    box-shadow: -2px 0 8px rgba(0,0,0,0.1);
    z-index: 99999999;
}

.booking__sidebar__container.spaceBetween{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px 36px;
}

.booking__sidebar__close {
    position: absolute;
    top: 50%;
    right: calc(45vw + 40px);
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1000;

    background: var(--secondary-white);
    width: 40px;
    height: 40px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.booking__sidebar__close img {
    width: 20px;
    height: 20px;
}

.booking__sidebar__header {
    font-size: 20px;
    font-weight: bold;
    padding: 20px 36px;
}

.booking__sidebar__header_top{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 13px;
}

.booking__sidebar__main_content{
    display: flex;
    flex-direction: column;
    gap: 45px;

}

.booking__sidebar__body {
    padding: 0 36px 20px 36px;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.booking__sidebar__body.noPadding{
    padding: 0;
}

.booking__sidebar__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    gap: 15px;
}

.booking__sidebar__row-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

/* FORM */

.booking__sidebar__form {
    display: flex;
    flex-direction: column;
    gap: 32px;

    padding-bottom: 20px;
}

.sidebar__form__container__group {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.sidebar__form__container__group_col{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.sidebar__form__container__group_row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 160px;
}

.sidebar__form__container__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 55px;
}

.sidebar__form__container__column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.sidebar__form__label {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
}
.is__full__width {
    width: 100%;
}

.sidebar__form__content {
    width: 100%;
}

.is__content__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.is__content__col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.row__wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
}

.row__wrap .checkbox {
    width: auto;
}

.is__content__row  .form__row.column{
    width: min-content;
}

.sidebar__form__container__row select, .sidebar__form__container__row label {
    min-width: 100%;
    width: 100%;
}

.is__content__col .form__row.checkbox {
    width: 100%;
}

.booking__sidebar__content{
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.booking__sidebar__content__col{
    display: flex;
    flex-direction: column;
    gap: 10px;

    margin-top: 10px;
}
.booking__sidebar__content__col__stretch{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    gap: 35px;

    width: 100%;
}

.booking__sidebar__content__group__border {
    border: 1px solid var(--neutral-grey-300);
    padding: 14px 18px;
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.booking__sidebar__content__blurred {
    filter: blur(4px);
}


/* TIME SLOT*/
.sidebar__hours__container{
    display: flex;
    flex-direction: column;
    gap: 20px;

    width: 100%;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);
}
.form-group__select-hours{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 35px;
}

.form-group__schedule_time:not(:first-child){
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.form-group__schedule_time{
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    gap: 20px;
    border-bottom: 1px solid var(--neutral-grey-300);
}

.form-group__schedule_time:last-child{
    border-bottom: none;
    padding-bottom: 0;
}

.form-group__row__schedule_time{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

.form-group__row__schedule_time__input_row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.form-group__row__schedule_time select{
    min-width: 80px;
    width: 100%;
    font-size: 14px;
}

.form-group__row__schedule_time .container__button__actions{
    margin-left: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
}

.container__button__actions button img{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.container__column__schedule_time{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
}


.container__generate__hours{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    width: 100%;

}

.time-slot__container__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
    width: 100%;
}

.time-slot__container__wrapper.smaller{
    gap: 15px;
    padding: 15px 0;
}


.time-slot__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 16px 24px;

    border-radius:8px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--neutral-grey-50);

    position: relative;

    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: var(--neutral-grey-600);
}

.time-slot__container.is__small{
    padding: 12px 16px;
    background: var(--secondary-white);
}


.time-slot__container .close{
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    cursor: pointer;
    border-radius: 100%;

    background: var(--secondary-white);
    border: 1px solid var(--neutral-grey-300);
    padding: 8px;
    width: 30px;
}

.date__range__container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
}

.form__row__dateRange{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.form__row__dateRange .form__col{
    width: 100%;
}

.button_dateRanges{
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 24px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
}

.button_dateRanges.primary{
    background: var(--primary-yellow);
    border: 1px solid var(--primary-yellow);
}


/* BOOKING SIDEBAR */
.sidebar__time-slot__schedule{
    display: flex;
    flex-direction: column;
}

.sidebar__time-slot__container__schedule{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;

    padding: 24px;
    border-radius: 12px 12px 0 0;
    border: 1.5px solid var(--neutral-grey-300);
}

.sidebar__time-slot__container__footer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    background: var(--neutral-grey-100);
    padding: 16px;
    border-radius: 0 0 12px 12px;
    border: 1.5px solid var(--neutral-grey-300);
    border-top: none;
}


/* ------------- MEDIA QUERIES ------------- */
@media screen and (max-width: 991px){

    .booking__sidebar__background{
        width: 100vw;
        height: 100dvh;

        align-items: flex-end;
        justify-content: flex-end;
    }

    .booking__sidebar__container{
        width: 100%;
    }

    .booking__sidebar__section{
        height: auto;
        width: 100vw;
        justify-content: stretch;
        align-items: stretch;

        max-height: 100vh;
    }

    .booking__sidebar__header{
        padding: 24px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }

    .booking__sidebar__header .text-20{
        max-width: 50%
    }

    .booking__sidebar__body{
        padding: 0 24px 20px 24px;
        width: 100%;
    }

    .sidebar__time-slot__container__schedule{
        padding: 16px;

        max-height: 200px;
        overflow: scroll;
    }

    .time-slot__container__wrapper{
        display: flex;
        flex-direction: column;
    }

    .checkbox-container{
        width: 100%;
    }
    .checkbox-container label{
        text-align: center;
        width: 100%;
    }

    .booking__container__actions{
        display: none;
    }

}




