.main-container__split{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 50px;
}

.container__right,
.container__left{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 40px;
}

.container__right{
    width: 100%;
    height: 100%;
}

.container__left{
    max-width: 400px;
    width: 100%;
}

.main-container__row__header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.main-container__row__header .title-18{
    width: 100%;
}

.main-container__row__header .form__row{
    width: auto;
}
    /* SOLDE */
.main-container__soldes{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;

}
.card__solde__container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 15px;
    width: 100%;
    height: 100%;
    padding: 24px;

    border-radius: 12px;
    border: 1px solid var(--neutral-grey-200);
    background: var(--secondary-white);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.card__solde__container .main-container__row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.icon__succeed{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 10px;
    background-color: var(--success-green-50);
}

.icon__waiting{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 10px;
    background-color: var(--warning-yellow-100);
}

.solde_title{
    font-size: 38px;
    font-weight: 500;
    color: var(--primary-sea);
    font-family: 'RedHatDisplay', sans-serif;
}


/* CUSTOMERS */
.payment__customers__container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    height: 100%;
    max-height: 400px;
    min-width: 350px;

    border-radius: 12px;
    border: 1px solid var(--neutral-grey-200);
    background: var(--secondary-white);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.payment__customers__container__header{
    display: flex;
    align-items: center;
    padding: 12px 24px;
    border-bottom: 1px solid var(--neutral-grey-200);
    background: var(--neutral-grey-50);
    border-radius: 12px 12px 0 0;
}

.payment__customers__container__scroll{
    padding: 12px;
}

.payment__customers__container__scroll{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 0;
    padding: 0;
    overflow-y: auto;
    max-height: 400px;
    height: 100%;
}

.payment__customers__container__scroll > .text-14{
    padding: 12px 24px;
}

.payment__customers__container__items{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 24px;
    border-bottom: 1px solid var(--neutral-grey-200);
    gap: 20px;
}

/* BANK */

.payment__bank__container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;
    width: 100%;
    height: 100%;
}

.bank_card{
    width: 100%;
    height: 100%;
    min-height: 200px;
    max-height: 200px;
    background: linear-gradient(135deg, #fdca21, rgb(255, 233, 156));
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;
}

.bank__card__header{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.bank__card__icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    background-blend-mode: overlay;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    border: 1px solid rgba(255, 255, 255, 0.75);
}

.bank__card__column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 5px;
}

.bank__name{
    font-size: 24px;
    font-weight: 500;
    color: var(--secondary-white);
    font-family: 'Poppins', sans-serif;
}

.bank__text{
    font-size: 16px;
    font-weight: 400;
    color: var(--secondary-white);
    font-family: 'Poppins', sans-serif;
}

.bank__iban{
    font-size: 18px;
    font-weight: 500;
    color: var(--secondary-white);
    font-family: 'Poppins', sans-serif;
}


/* CONNECTED STRIPE */
.stripeBooking{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100%;
}

.main__container__full{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width:500px;
    gap: 24px;
}

.marginTop{
    margin-top: 50px;
}

.icon__image img{
    width: 80px;
    height: 80px;
}

.main__container__content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100%;
}