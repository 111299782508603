.bg_sidebar{
    background: rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.bg_sidebar.open{
    z-index: 99999;
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar{
    background: var(--secondary-white);
    border-left: 1px solid var(--neutral-grey-200);
    padding: 20px;
    position: fixed;
    width: 400px;
    max-width: 400px;
    height: 100%;
    top: 0;
    right: -450px;
    z-index: 1;
    overflow: scroll;
    box-shadow: 0 0 3px 0 rgba(16, 24, 40, 0.1);
    transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar.open{
    right: 0;
    transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar__container,
.sidebar__container__user,
.sidebar__container__user__infos,
.form-container{
    height: 100%;
}


.sidebar__container__user__avatar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.sidebar__container__user__avatar img{
    cursor: pointer;
}

.sidebar .form-container form{

    gap: 10px;
}

.sidebar .form__col{
    width: 100%;
}

.sidebar .form__row.button{
    margin-top: 30px;
}