.activity-preview{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    padding-bottom: 50px;
    padding-top: 60px;
}

.preview-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px;
}

.preview-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 20px;
    height: 50px;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;

    background-color: #FFFFFF40;
    backdrop-filter: blur(5px);
}
.preview-card{
    padding: 10px 20px 0px 20px;
}



.activity-card{
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #FFFFFF;
    border-radius: 18px;
    border: 1px solid var(--neutral-grey-300);
    overflow: hidden;
    width: 100%;
    position: relative;
}

.type_card{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 9999;

    font-size: 28px;
    font-weight: 600;
    color: #FFFFFF;
    font-family: 'RedHatDisplay', sans-serif;
    width: 90%;
    text-align: center;
    text-transform: uppercase;
}

.activity-card-image,
.activity-card-image-single{
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activity-card-image-single{
    background: var(--neutral-grey-300);
    height: 200px;
}

.activity-card-image img,
.activity-card-image-single img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.activity-details{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 32px;
    flex: 1;
}

.activity-container{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.activity-icon-item{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 18px;
}

.activity-icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    border: 1px solid #E0E0E0;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    max-width: 50px;
    max-height: 50px;
}

.activity-text{
    width: 100%;
}


.preview-footer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    gap: 16px;

    padding-bottom: 40px;
}

.footer-links{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.badge,
.badge img{
    width: 100%;
}