.tabBlock{
    display: flex;
    align-items: flex-start;
    width: fit-content!important;

    border-radius: 8px;
    border: 1px solid var(--neutral-grey-300);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    overflow: hidden;
}

.tabBlock__tabs{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content;
    height: 100%;
    padding: 0px 0px;
    overflow: hidden;
}

.tabBlock__tabs__tab{
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-right: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);

    transition: background 0.2s ease-in-out;
    cursor: pointer;
}

.tabBlock__tabs__tab:last-child{
    border-right: 0px;
}

.tabBlock__tabs__tab:not(:last-child){
    border-right: 1px solid var(--neutral-grey-300);
}

.tabBlock__tabs__tab.active{
    background: var(--neutral-grey-100);
}

.tabBlock__tabs__tab:hover{
    background: var(--neutral-grey-100);
}