.subscription{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 60px 80px;
    gap: 50px;
    position: relative;
    z-index: 10;
    overflow: hidden;
}

.subscription__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.welcome__subscription{
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.frimity_loop{
    position: absolute;
    bottom: -20px;
    right: 0;
    width: 700px;
    height: 100%;
    z-index: -1;
}

/* BILLING */
.light-padding{
    padding: 30px;
    gap: 30px;
}

.subscription__content__col{
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
    border-bottom: 1px solid var(--neutral-grey-200);
    width: 100%;
}

.subscription__content__col:first-child{
    padding-top: 0px;
}

.subscription__content__row{
    display: flex;
    padding: 20px 0px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
    border-bottom: 1px solid var(--neutral-grey-200);
}

.subscription__content__row:last-child,
.subscription__content__col:last-child{
    border-bottom: none;
}

.stripe-payement__row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
}

.subscription__content__col__title{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    width: 100%;
    max-width: 350px;
}
.is__col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.subscription__content__col__content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.stripe-payement__col p{
    max-width: 250px;
}

.invoice__row{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.invoice__row .icon-btn img{
    width: 20px;
    height: 20px;
}

.invoice__row .btn{
    grid-gap: 0;
}

.gap-25{
    gap: 25px;
}
.subscription__content__row form{
    grid-gap: 20px;
}
.subscription__content__row .form__row.column{
    width: 100%!important;
}
.subscription__content__row .form__row{
    width: 100%!important;
}
.subscription__content__row .form__row .form__row.column{
    width: 100%!important;
}
.subscription__content__row .form__row__label, .subscription__content__row .row__input > label{
    max-width: 100%;
    min-width: 100%;
}

.end{
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

/* PAYEMENT */
#payment-form{
    width: 100%;
    align-self: center;
}

.subscription__plan__price{
    color: #000;
}

/* CONFIRMATION */


.confirm_payement .confirm_payement__text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    text-align: center;
    width: 100%;
}

.confirm_payement .confirm_payement__text .btn{
    width: 90%;
}


/* LOGIN */
.subscription__grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;

    max-width: 1000px;
}

.subscription__grid-area-1{
    grid-area: 1 / 1 / 2 / 2;
    text-align: center;
}

.subscription__grid-area-2{
    grid-area: 1 / 2 / 2 / 3;
    text-align: center;
}

.subscription__grid-area-3{
    grid-area: 2 / 1 / 3 / 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.subscription__grid-item{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    width: 100%;
}


.subscription__grid-item__partner{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 100%;

    border: 1px solid var(--neutral-grey-300);
    border-radius: 15px;
    padding: 15px;
}


.subscription__grid-item__plan{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    width: 100%;

    border: 1.5px solid var(--primary-yellow);
    border-radius: 15px;
    padding: 15px;
}

.subscription__grid-item__plan__content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    width: 100%;
}

.subscription__grid-item__plan input[type="checkbox"],
.stripe_partner_item input[type="checkbox"]{
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    width: 18px;
    height: 18px;
    padding: 1.5px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--neutral-grey-400);
    cursor: pointer;

    transition: all 0.2s ease-in-out;
}

.subscription__grid-item__plan input[type="checkbox"]:checked,
.stripe_partner_item input[type="checkbox"]:checked{
    border: 1px solid var(--primary-yellow);
    background: var(--warning-yellow-50);
    transition: all 0.2s ease-in-out;
}

.subscription__grid-item__plan input[type="checkbox"]:checked::before,
.stripe_partner_item input[type="checkbox"]:checked::before{
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 1px;
    background: url('../../assets/icones/global/check_stroke.svg') no-repeat center/cover;
}


/* Sidebar container */
.subscription-main{
    position: relative;
}
.subscription-main .sidebar {
    width: 300px;
    background-color: #f7f7f7;
    border-right: 1px solid #ddd;
    padding: 20px;
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: auto;
}

/* Sidebar header */
.sidebar__header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Sidebar item */
.sidebar__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sidebar__item:hover {
    background-color: #eee;
}

/* Active sidebar item */
.sidebar-subscription {
    background-color: #007bff;
    color: white;
}

.sidebar-subscription {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar-subscription.open {
    z-index: 99999;
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar-subscription__container {
    background: var(--secondary-white);
    border-left: 1px solid var(--neutral-grey-200);
    padding: 20px;
    position: fixed;
    width: 50vw;
    max-width: 50vw;
    height: 100%;
    top: 0;
    right: -50vw;
    z-index: 1;
    overflow: scroll;
    box-shadow: 0 0 3px 0 rgba(16, 24, 40, 0.1);
    transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.open .sidebar-subscription__container {
    right: 0;
    transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
}


.sidebar-subscription  .welcome__subscription{
    width: 100%;
}


/* STRIPE */
.flex-end{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    width: 100%;
}

.area-end{
    grid-area: 3 / 2 / 4 / 3;
    text-align: right;
}

.stripe_partner_item{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    width: 100%;

    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 15px 20px;
}

.stripe_partner_item.active{
    border: 1px solid var(--primary-yellow);
}

.connectAccount .form__radio__group{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    width: 100%;
}

.connectAccount .form__radio{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;


    border-radius: 12px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);
    cursor: pointer;
    padding: 15px;

    transition: all 0.2s ease-in-out;
    position: relative;

    height: 100%;
}

.connectAccount .form__radio:hover{
    border: 1px solid var(--primary-yellow);
    background: var(--warning-yellow-50);
}

.form__radio__label{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
}

.connectAccount .form__radio input{
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;
    cursor: pointer;
}


/* Change styles when the input is checked */
.connectAccount .selected{
    border: 1px solid var(--primary-yellow);
    background: var(--warning-yellow-50);
}


