.main.signin{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    padding: 20px 0px 0px 0px;
}
.main__container.infos{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.main__container.infos.partner_infos{
    height: auto;
}

.main__container.check{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.main__container__image{
    display: flex;
    padding: 90px 95px;
    min-height: 500px;
    max-height: 450px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    flex: 1 0 0;
    border-radius: 90px 0px 0px 90px;
    background: #040320;
    height: 100%;
    width: 45%;
}

.main__container__infos{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.main__container__image p,
.main__container__image h1
{
    color: var(--secondary-white);
}

.main__container__section{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.check .main__container__section{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.main__container__form{
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 35px;

    width: 500px;
    height: 100%;
}

.main__container__form .address{
    width: 100%;
}
.check .main__container__form{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 35px;
}

.main__container__form__row__title{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
}

.main__container__form__row__title .btn{
    padding: 5px 10px;
}

.main__container__form__row__title .btn img{
    width: 15px;
    height: 15px;
}



.form__signin{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
}

.form__signin__row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 15px;
}

.form__signin__col{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
    margin: 20px 0px;
}

.form__signin__row .form__row.column,
.form__signin__row .column .form__col label{
    width: fit-content;
}

.form__signin__row .column .form__co,
.form__signin__row .column .form__col input{
    width: 100%;
}

.form__signin__row label{
    min-width: 100px;
}

.stars{
    width: 70px;
}

.last{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
}

.check .main__container__form h2{
    text-align: left;
}

.check a{
    width: 100%;
}

.form__signin .form__col.radio,
.form__signin .form__row.radio{
    width: 100%;
}

.main__container__col32 .text-14.light.pointer{
    max-width: 100%;
}


.invite{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    width: 100%;
}

/* MOBILE */
@media screen and (max-width: 991px){
    .main.signin{
        padding: 40px 0px 0px 0px;
    }
    .main__container.infos{
        height: auto;
        margin-top: 40px;
    }
    .main__container__image{
        display: none;
    }

    .main__container__section{
        padding: 0 30px;
    }

    .main__container__form{
        width: 100%;
    }

    .main__container__section,
    .main__container__form.user_signin{
        width: 100%;
    }

    .main__container__form__col{
        gap: 25px;
    }

    .form__signin__row,
    .form__signin{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .user_signin .form__col.radio label{
        line-height: 20px;
    }

    .user_signin .form__col.radio input[type="radio"]{
        width: 21px;
    }

    .form__col.address{
        width: 100%;
    }

    .check .main__container__section{
        width: 100%;
        margin-top: 40px;
    }
}