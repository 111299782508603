.main-booking__container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    height: fit-content;

    border-radius: 21px;
    padding: 24px 24px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);
}

/* MANAGE */

.booking__container__header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
}

.booking__container__analytics__item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    background: var(--secondary-white);
    padding: 16px;
    border-radius: 8px;

    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.booking__container__list .table__item{
    width: 100%;
}

.table__booking__row__infos{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
}

.table__booking__row__infos__wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    max-width: 80%;
}

.table__booking__infos{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}


.table__booking__infos img{
    width: 20px;
    height: 20px;
}

.table__booking__separation_line{
    width: 2px;
    border-radius: 3px;
    height: 20px;
    background: var(--neutral-grey-500);
}

.table__booking__row__actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: auto;
    flex-wrap: wrap;
}

/*SCHEDULE */
.booking__container__actions{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.container__action_item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: auto;
}

.container__action_item .form__col{
    width: auto;
}

.booking__container__schedule{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    max-width: 100%;
}

.schedule__container__calendar{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    width: 100%;

    max-width: 350px;
}


.schedule__calendar__datepicker{
    width: 100%;
}

.schedule__calendar__infos{
    background: var(--secondary-white);
    border-radius: 12px;
    border: 1px solid var(--neutral-grey-200);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1px;
    width: 100%;
    overflow: hidden;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.schedule__calendar__infos > div {
    background: var(--secondary-white);
    padding: 15px 25px;
}

.schedule__calendar__infos > div:not(:nth-child(2n)) {
    border-right: 1px solid var(--neutral-grey-200);
}

.schedule__calendar__infos > div:nth-child(-n+2) {
    border-bottom: 1px solid var(--neutral-grey-200);
}

.schedule__calendar__infos__item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: auto;
    padding: 14px 19px;
}

.schedule__calendar__infos__item__pastille{
    width: 35px;
    height: 35px;
    border-radius: 100%;
}

.schedule__calendar__infos__item p{
    text-align: center;
}

.schedule__calendar__infos__item__pastille.white{
    background: var(--secondary-white);
    border: 1px solid var(--neutral-grey-200);
}

.schedule__calendar__infos__item__pastille.grey{
    background: var(--neutral-grey-200);
}

.schedule__calendar__infos__item__pastille.yellow{
    background: var(--warning-yellow-200);
}

.schedule__calendar__infos__item__pastille.small.yellow{
    width: 10px;
    height: 10px;
    background: var(--primary-yellow);
}

.booking__container__list{
    width: 100%;
}


/* TABLE BOOKING */
.table__booking{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
}

.table__booking__img{
    min-width: 70px;
    width: 70px;
    height: 70px;
    border-radius: 12px;
    background: var(--neutral-grey-200);
    object-fit: cover;
}

.table__booking__col{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.table__booking__col__header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
}

.table__booking__col__header__actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}


/* CONFIG API */
.booking_container__column .form-section__row__col{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.booking_container__column .textInfos{
    width: 100%;
    min-width: 350px;
    max-width: 350px;
    text-align: left;
}


/* NO-BOOKINGS */

.booking_container__column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    text-align: center;

}

.container__buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
}


.booking_input_api{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
}


/*INPUT SELECT*/

.container__buttons .form__col{
    width: 100%;
    min-width: 23vw;
}

/*OPTIONS*/

.text_grey{
    color: var(--neutral-grey-600);
    font-weight: 400;
}

.text_bold{
    font-weight: 500;
}

.padding-80{
    padding: 80px 0px;
}
.padding-20{
    padding: 20px 0px;
}

.is__center {
    justify-content: center;
    align-items: center;
}

.not-center{
    text-align: left;
}

.width-60{
    width: 40vw;
    max-width: 40vw;
}

.gap-35{
    gap: 35px;
}

.separation-line{
    width: 100%;
    height: 1px;
    background: var(--neutral-grey-200);
    margin: 20px 0 10px 0;
}



/* ------------- MEDIA QUERIES ------------- */
@media screen and (max-width: 991px){
    .booking__container__header{
        display: none;
    }

    .schedule__container__calendar{
        display: none;
    }

    .table__booking__col__header{
       flex-direction: column;
        align-items: flex-start;
        gap: 2px;
    }

    .table__booking__img{
        width: 40px;
        height: 45px;
        min-width: 45px;
        border-radius: 8px;

        object-fit: cover;
    }

}