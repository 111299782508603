.header__container__offer{
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 5px;

    border-radius: 150px;
    border: 1px solid #D0D5DD;
    background: var(--secondary-white);
}

.header__container__offer img{
    width: 18px;
    height: 18px;
}

.header__container__offer p{
    text-align: center;
    color: #4F4F4F;
}
.header__container__col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.activities{
    gap: 10px!important;
}

/* Category */
.formInfos.category{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 52px;
}
.category__col{
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}
.category__col .light{
    color: var(--neutral-grey-500);
}
