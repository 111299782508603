.tab_bar{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    height: 35px;
    border-bottom: 1px solid #e6e6e6;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.tab_bar__tab{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    position: relative;

    color: var(--neutral-grey-500);

    font-family: Poppins, sans-serif;
    font-size: 15px;
    font-weight: 500;
    padding: 1px 4px 11px 4px;
}

.tab_bar__row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.tab_bar a{
    color: var(--neutral-grey-500);
    text-decoration: none;
}

.tab_bar__tab.active{
    color: var(--typography-black);
    font-weight: 600;
}
.tab_bar__tab.active::after{
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: var(--primary-yellow);
}

.tab_bar__tab__index{
    display: flex;
    padding: 2px 10px;
    align-items: center;

    border-radius: 16px;
    background: var(--primary-yellow);
    mix-blend-mode: multiply;

    color: var(--neutral-grey-700);
    text-align: center;

    /* Text xs/Medium */
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
}