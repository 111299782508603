.verification{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    width: 100%;
    height: 100vh;
    padding-top: 80px;
}
.main__container__col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    width: 100%;

    max-width: 320px;
}

.width-400{
    max-width: 450px;
    margin-top: 40px;
}

.width-600{
    max-width: 600px;
    margin-top: 40px;
}

.has-margin-top{
    margin-top: 80px;
}

.main__container__col.password{
    max-width: 460px;
    gap: 40px;
}

.main__container__col.password-half{
    max-width: 350px;
    gap: 40px;
}

.password .main__container__col32,
.password-half .main__container__col32{
    gap: 20px;
    text-align: center;
}

.main__container__col.password form{
    max-width: 350px;
}
.password-half .main__container__col32 .text-14,
.password .main__container__col32 .text-14{
    max-width: 350px;
    display: block!important;
}


.main__container__col32{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.main__container__col32 .text-14{
    color: var(--neutral-grey-600);
    font-weight: 300;
}

.margin-32{
    margin-top: 32px;
}

.main__container__col32 > p,
.main__container__col32 > a,
.main__container__col32 div{
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.action_row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.action_row a{
    width: auto;
}

.digit_code{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.noMaxWidth{
    max-width: none;
    width: auto;
}