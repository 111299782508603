.main-container__row.list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    gap: 24px;
}

.table-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
}

.main-table{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    table-layout: auto;
    border-collapse: collapse;

    border-radius: 12px;
    border: 1px solid var(--neutral-grey-200);
    background: var(--secondary-white);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

}

thead, tr, tbody, tfoot{
    width: 100%;
}

thead{
    display: flex;
    align-items: center;
    padding: 12px 24px;
    border-bottom: 1px solid var(--neutral-grey-200);
    background: var(--neutral-grey-50);
    border-radius: 12px 12px 0 0;
}

thead tr{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

thead th{
    color: var(--neutral-grey-600, #475467);
    /* Text xs/Medium */
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;

    width: 100%;
    text-align: left;
}

tbody tr{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 24px;
    border-bottom: 1px solid var(--neutral-grey-200);
}

tbody tr:last-child{
    border-bottom: 0px solid var(--neutral-grey-200);
}

tbody td{
    width: 100%;

    color: var(--neutral-grey-600, #475467);
    /* Text sm/Medium */
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
}

.table__item{
    width: fit-content;
}

tfoot{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
}

.pagination{
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-top: 1px solid var(--neutral-grey-200);
}

.pagination .pagination__button{
    width: 80px;
}

.pagination__button.last{
    left: 0;
}

.pagination .pagination__button.width-100{
    width: 100px;
}

.pagination__group{
    display: flex;
    gap: 35px;
    align-items: center;
}

.pagination__group__item{
    display: flex;
    gap: 5px;
    align-items: center;
}

.pagination__group__item__input{
    display: flex;
    gap: 0px;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid var(--neutral-grey-300);
    border-radius: 8px;
    padding: 0 5px;
    text-align: center;
    color: var(--neutral-grey-600);
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
}

.pagination__group__item__input::-webkit-outer-spin-button,
.pagination__group__item__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.pagination__group__item__input {
    -moz-appearance: textfield;
}

.items-per-page{
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.items-per-page label{
    color: var(--neutral-grey-600);
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;

}

.items-per-page select{
    width: 100%;
    min-width: 60px!important;
    max-width: 70px;
    height: 40px;
    border: 1px solid var(--neutral-grey-300);
    border-radius: 8px;
    padding: 0 15px;
    color: var(--neutral-grey-600);
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    background-position: right 8px center;
}

.items-per-page select:focus{
    outline: none;
}


.table-row__disabled{
    opacity: 0.6;
    background: var(--neutral-grey-100);
}
.table-row__disabled img{
    opacity: 0.6;
}

.clickable-row{
    cursor: pointer;
}

.clickable-row:hover{
    cursor: pointer;
    background: var(--primary-yellow-50);
}


/* ------------- MEDIA QUERIES ------------- */
@media screen and (max-width: 991px){
    .main-table{
        border-radius: 0;
    }
}