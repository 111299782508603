.stats_title{
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: var(--primary-sea);
}

.stats_cards,
.stats_rounds{
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 24px;
}


.stats_cards .card-stat{
    width: 100%;
}

.stats_cards .card-stat:nth-child(3){
    grid-area: 2 / 1 / 2 / 3;
}

/* SOCIAL MEDIA */

.inputs__container-social-media{
    display: flex;
    flex-direction: column;
    gap: 12px;

    width: 100%;
}

.inputs__container-social-media .form__row__icon{
    opacity: 1;
    width: 30px;
    height: 30px;
}

.inputs__container-social-media .form__row__icon img{
    opacity: 1;
    width: 30px;
    height: 30px;
}

.inputs__container-social-media .row__input__radio,
.inputs__container-social-media .form__row,
.inputs__container-social-media .form__col__icon{
    width: 100%;
}

.inputs__container-social-media .form__row__input{
    gap: 12px;
}
