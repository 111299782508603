.step-bar{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.step-bar__container{
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    gap: 90px;

}

.step-bar__container__step{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;

    position: relative;
}

.step-bar__container__step__circle{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.step-bar__container__step__circle__step{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1.5px solid var(--neutral-grey-300);
    background: var(--secondary-white);
    z-index: 1;
}

.step-bar__container__step .text-12{
    text-align: center;
    width: 120px;
}

.step-bar__container__step__circle::before{
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--neutral-grey-300);
    position: absolute;
    z-index: 2;
}

.active .step-bar__container__step__circle__step{
    border: 1.5px solid var(--primary-sea);
    background: var(--primary-yellow);
}
.step-bar__container__step__circle.active::before{
    background: var(--primary-sea);
    z-index: 2;
}

.step-bar__container__step__circle.active::after{
    background: #FFF0C1;
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    z-index: 0;
}

/* add line between circles and connect it to all circles */
.step-bar__container__step:not(:last-child)::before{
    content: '';
    width: 230px;
    height: 1.5px;
    background: var(--neutral-grey-300);
    position: absolute;
    top: 9px;
    left: 70px;
    z-index: 0;
}

.step-bar__container__step.active:not(:last-child)::before{
    background: var(--primary-yellow);
}


@media screen and (max-width: 991px) {
    .step-bar{
        padding: 0 20px;
    }
    .step-bar__container {
        gap: 20px;
    }

    .step-bar__container__step:first-child::before, .step-bar__container__step:nth-child(2)::before{
        width: 115px;
        left: 53px;
        top: 7px;
    }

    .step-bar__container__step{
        text-align: center;
        font-size: 11px;
    }

    .step-bar__container{
        align-items: baseline;
    }

    .step-bar__container__step__circle__step{
        width: 15px;
        height: 15px;
    }

    .step-bar__container__step__circle::before{
        content: '';
        width: 6px;
        height: 6px;
    }

    .step-bar__container__step__circle.active::after{
        width: 25px;
        height: 25px;
    }
}