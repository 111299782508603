.stripe-payement{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.stripe-payement__container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    text-align: left;

    background: var(--secondary-white);
    width: 100%;
    padding: 60px 80px;

    position: relative;
    overflow: hidden;
}

.checkout{
    overflow: scroll;
}

.is__grey{
    background: var(--neutral-grey-50);
    border-left: 1px solid var(--neutral-grey-200);
}

.stripe-payement__container .icon-btn img{
    width: 18px;
    height: 18px;
}

.stripe-payement__container__infos{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    padding-bottom: 32px;
    border-bottom: 1px solid var(--neutral-grey-300);

}

.stripe-payement__container__infos:last-child{
    border-bottom: none!important;
}

.pastille-stripe{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    align-self: flex-start;
    padding: 3px 9px;
    border-radius: 12px;

    width: auto;

    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}

.yellow{
    background: var(--primary-yellow);
    mix-blend-mode: multiply;
    color: var(--secondary-black);
}

.black{
    background: var(--primary-sea);
    color: var(--secondary-white);
}


.stripe-price{
    color: var(--secondary-black);
    font-family: 'RedHatDisplay', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 50% */
}

.stripe-payement__col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.stripe-payement__col.end{
    align-items: flex-end;
}

.is__row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.gap-3{
    gap: 3px;
}

.gap-8{
    gap: 8px;
}

.light{
    color: var(--neutral-grey-600);
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
}


/*____PAYEMENT____*/
.stripe-payement__container__card,
.stripe-payement__container__card,
.payment-methods{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    width: 100%;
}

.payment-methods{
    gap: 35px;
}

.payment-methods form,
.StripeElement.StripeElement--empty,
#payment-element{
    width: 100%;
}

.payment-methods__container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    width: 100%;
}

#payment-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

#payment-form.hidden{
    display: none;
}

.payment-methods__container__payement{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    width: 100%;
}


.payment-methods__row{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    cursor: pointer;
}
.payment-methods__row img{
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.payment-methods__col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
}

.payment-methods__col .form__signin__col_payment{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
    margin-bottom: 0;
}



/*____CONFIRMATION____*/
.confirm_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    background: none;
    position: relative;
}

.main__container.confirm_payement{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    z-index: 10;
    position: relative;
}

.confirm_container .frimity_loop{
    bottom: -130px!important;
    width: 500px!important;
    z-index: 1!important;
}

