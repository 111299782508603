form{
    align-items: flex-start;
    grid-gap: 0px;
}
.form-container{
    width: 100%;
}

.form-section{
    width: 100%;
    border-top: 1px solid var(--neutral-grey-200);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding: 20px 0 25px 0;
}

.form-section__line{
    width: 100%;
    border-top: 1px solid var(--neutral-grey-200);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    padding: 32px 0;
}

.form-section__line:first-child{
    padding-top: 0;
    border-top: 0px;
}

.form-section__row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
}

.form-section__row__col{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.form__col{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    gap: 8px;

    width: 80%;
    height: 100%;
}

.form__col.no-gap{
    gap: 5px;
}

.row__actions{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 16px;
}

.flex-20{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    width: 100%;
}

.form__col__modalities{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    width: 90%;
}

.formInfos.flex-20 label{
    min-width: auto;
    max-width: 250px;
    width: 100%;
}

.formInfos.flex-20 .form__row{
    width: 100%!important;
    gap: 10px;
}

.grid-3 .form__row.column{
    max-width: 200px;
}

.grid-3 .form__row.column select{
    min-width: auto;
}

.column .form__col,
.column label{
    width: 100%;
    text-align: left;
}

.form__row.row__input > label{
    min-width: 350px;
}

.is__full{
    min-width: 100%;
}

.is__full .textInfos{
    display: none;
}
.is__full .formInfos{
    width: 100%;
}

.form__row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    gap: 8px;

    width: 80%;
    height: 100%;
}

.form__notFull{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.form__notFull label{
    min-width: auto;
}

.form__row.checkbox{
    gap: 15px;
}

.form__row.button{
    width: 100%;
    justify-content: flex-end;
}

.form__row.column{
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%!important;
}

.form__row.column.max_width{
    min-width: 6vw;
    max-width: 9vw;
}

.form__row.column.max_width label{
    font-size: 14px;
}

.pricing__row__input{
    max-width: 200px;
}

.pricing__row .form__row,
.pricing__row select{
    max-width: 9vw;
    min-width: auto;
}

.form__col_row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.form__container__row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
}

.form__container__row label{
    min-width: 100px;

}

.form__col_row .form__row select{
    align-self: flex-start;
}

.formInfos .form__row,
.formInfos .form__col{
    width: 100%;
}

.form__container__col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    padding: 0px;
}

.form__container__col.categories{
    gap: 20px;
}

.formInfos.category{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
}

.form__container__col.grid{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 23px;
}

.form-section__row__col.to-know{
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.form__col.column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

/*toKnox tag*/
.add-option-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.form_input_row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: 100%;
}

.options-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.option-item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 5px 15px;
    border-radius: 8px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--neutral-grey-50);

    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: var(--typography-black);

    position: relative;
}

.option-item__delete{
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 10px;
    border-radius: 50%;
    background: var(--secondary-white);
    border: 1px solid var(--neutral-grey-300);

    cursor: pointer;
    width: 20px;
    height: 19px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.option-item__delete img{
    width: 15px;
    height: 15px;
}


.to-know .form__title{
    color: var(--neutral-grey-700);
    /* Text sm/Medium */
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.to-know .form__row.grid{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
}

.cancel .form-section__row__col.formInfos{
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.access-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
}

.access-container mapbox-address-autofill{
    width: 100%;
}

.access-container .input{
    width: fit-content;
    max-width: 150px!important;
}

.access-container .readOnly{
    color: var(--neutral-grey-400)!important;
}

.error-message{
    color: var(--error-red-100);
    opacity: 0.7;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 142.857% */
}

.error{
    border: 1px solid var(--error-red-100) !important;
}

.error input::placeholder{
    color: #ff9b9b;
}

.error .form__row__icon img{
    /*make it red*/
    filter: invert(80%) sepia(50%) saturate(6000%) hue-rotate(330deg) brightness(110%) contrast(90%);
}


/* PRICING OPTIONS */
.container_top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.pricing__row .tag-input-container .form__row {
    max-width: inherit;
}

.tag-input-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 10px;
    width: 100%;
}

.tags-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
}

.tag-item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 15px;
    border-radius: 8px;
    background: var(--neutral-grey-100);

    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--neutral-grey-500);

    position: relative;
}

.close_tag{
    width: 15px;
    cursor: pointer;
}


input:focus-visible {
    outline: none;
}


.price-symbol{
    color: var(--neutral-grey-700);
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.form__col__gap-32{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
}

.textInfos{
    width: 100%;
    min-width: 350px;
    max-width: 350px;
}


.textInfos h2{
    max-width: 75%;
    color: var(--neutral-grey-700);

    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.textInfos p{
    max-width: 85%;
    color: var(--neutral-grey-500);

    /* Text sm/Regular */
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
}

.form-section__row__col.formInfos.isRow{
    display: flex;
    flex-flow: row wrap!important;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    gap: 32px!important;
}



.form__row__input{
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    background: var(--secondary-white);

    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--typography-black);
}

.form__row__input__password{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 8px;
    gap: 8px;
    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    background: var(--secondary-white);

    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--typography-black);
}

.form__row__input__password input{
    width: 100%;
}

.form__row__input__password .form__row__icon{
    width: auto;
}


.form__input__icon{
    width: 100%;
    border: none;
    outline: none;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--typography-black);
}
.form__input__icon::placeholder{
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: rgba(180, 180, 180, 1);
}


.form__row__icon{
    width: 20px;
    height: 20px;
    opacity: 0.7;
}

.form__col__icon{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 10px;
}

.form__row__icon.email{
    background: url('../../../assets/icones/global/mail.svg') no-repeat center;
}

.form__row__icon.password{
    background: url('../../../assets/icones/global/lock.svg') no-repeat center;
}

.form__row__icon img{
    width: 20px;
    height: 20px;
}

.form__row__label,
.row__input > label{
    min-width: 350px;
    color: var(--neutral-grey-700);

    /* Text sm/Medium */
    font-family: Poppins, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.form__input{
    width: 100%;
    display: flex;
    align-self: stretch;
    align-items: center;
    gap: 8px;
    padding: 10px 14px;

    border-radius: 8px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);

    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.form__input.readOnly{
    background: var(--neutral-grey-100)!important;
    border: 1px solid var(--neutral-grey-300)!important;
    cursor: not-allowed;
}

.form__input.disabled,
.form__row__input.disabled{
    background: var(--neutral-grey-100)!important;
    border: 1px solid var(--neutral-grey-300)!important;
    color: var(--neutral-grey-500);
    cursor: not-allowed;
}
.form__row__input.disabled input{
    cursor: not-allowed;
    color: var(--neutral-grey-500);
}

.subtitle{
    color: var(--neutral-grey-500);
}

.row__input{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    gap: 15px;
    width: 80%;
    height: 100%;
}

.input-code{
    display: flex;
    width: 80px;
    padding: 10px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;

    border-radius: 8px;
    border: 2px solid var(--primary-yellow);
    background: var(--secondary-white);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    color: var(--primary-sea);
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;

    appearance: none;
}

.input-code {
    /* Hide the spinner for Firefox */
    -moz-appearance: textfield;
}

/* For Chrome, Safari, and Edge */
/* Hide the spinner for Chrome, Safari, and Edge */
.input-code::-webkit-inner-spin-button,
.input-code::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}



/* RADIO */
.row__input__radio {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    gap: 25px;
    width: 80%;
    height: 100%;
}

.radio-container,
.checkbox-container{
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;

    border-radius: 8px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    width: fit-content;
    cursor: pointer;
    position: relative;
}

.radio-container input[type="radio"],
.checkbox-container input[type="checkbox"]{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    font-size: 14px;
}

.checkbox-container.disabled{
    cursor: not-allowed!important;
    background: var(--neutral-grey-100);
    color: var(--neutral-grey-500);
}

.checkbox-container.disabled label{
    color: var(--neutral-grey-500);
}

.radio-container.disabled{
    cursor: not-allowed!important;
    background: var(--neutral-grey-100);
}

.radio-container.disabled input[type="radio"]{
    cursor: not-allowed!important;
}

.radio-container.disabled label{
    color: var(--neutral-grey-500);
}

.radio-container label,
.checkbox-container label{
    color: var(--neutral-grey-700);

    /* Text sm/Medium */
    font-family: Poppins, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}

.radio-container.checked,
.checkbox-container.checked{
    background: var(--primary-yellow);
}

.form__row.radio.error{
    border: 0px!important;
}

.form__row.radio.error input[type="radio"]{
    border: 1px solid var(--error-red-100)!important;
}

.form__row.radio.error .text-14{
    color: var(--error-red-100)!important;
}

/* RADIO TYPE */


.radio-container.checked.type__item{
    border-radius: 8px;
    border: 1px solid var(--primary-yellow);
    background: var(--warning-yellow-50);

    /* Shadow/xs focused 4px primary-100 */
    box-shadow: 0px 0px 0px 4px #FFFCEC, 0px 1px 2px 0px #FFE889;
}

/* INPUT FILES */

.input__files__container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    gap: 8px;

    min-width: 350px;
    width: 90%;

}

.form__col.col__files label{
    max-width: 85%;
}

.text_files{
    max-width: 350px;
}

.col__files{
    gap: 16px;
}

.col__files.text{
    max-width: 350px;
}

.input__files__custom{
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    border-radius: 12px;
    border: 2px dashed var(--neutral-grey-200);
    background: var(--secondary-white);
    position: relative;
    height: 100%;
}

.input__files__custom__text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.input__files__custom input[type="file"]{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.files-added{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}
.file-container__col,
.file-container.download{
    width: 100%;
}
.file-container__col{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.file-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid var(--neutral-grey-200);
    background: var(--secondary-white);
    position: relative;
}
.file-container.download{
    border: 1px solid var(--primary-yellow);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    gap: 5px;
}

.file-container.download p::after{
    content: url('../../../assets/icones/global/yellow_check.svg');
    margin-left: 8px;

    position: absolute;
    top: 15px;
    right: 15px;
}

.file-container.download span{
    color: var(--neutral-grey-500);
    font-size: 14px;
}

.file-container__col .btn{
    width: 40px;
    height: 40px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.file-container__col .btn img{
    width: 20px;
    height: 20px;
}

/* INPUT IMAGES */
.input__images__container{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
}

.input__images__container.center{
    justify-content: center;
    align-items: center;
}

.input__images__custom{
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    border-radius: 12px;
    border: 2px solid var(--primary-yellow);
    background: var(--secondary-white);
    position: relative;
    width: 100%;
}

.input__images__custom input[type="file"]{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.images-added{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.image-container__col{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}


.image-container.download{
    border-radius: 12px;
    overflow: hidden;
    max-width: 200px;
    max-height: 200px;
    min-width: 200px;
    height: 150px;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid var(--neutral-grey-300);
}

progress[value]::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 15px;
    border: 1px solid var(--neutral-grey-300);
}

progress[value]::-webkit-progress-value {
    background-color: var(--primary-yellow);
    border-radius: 15px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

.empty{
    max-width: 200px;
    max-height: 150px;
    min-width: 200px;
    min-height: 150px;
    padding: 10px 20px;
    text-align: center;
    border: 1px dashed var(--neutral-grey-300)!important;

}
.empty p{
    color: #B4B4B4;
}
.image-container__col__icons{
    position: absolute;
    top: 5px;
    right: 0px;
    padding: 5px 10px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}
.image-container .icon-btn{
    padding: 5px 10px;
}
.image-container .icon-btn img{
    width: 20px!important;
    height: 20px!important;
}
.image-container.download img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.image-container .icon-btn:last-child{
    background: var(--error-red-80);
    border: 1px solid var(--error-red-80);
}


/* TEXTAREA */
.form__textarea{
    display: flex;
    padding: 12px 14px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    width: 100%;

    border-radius: 8px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    resize: none;
    min-height: 150px;
    max-height: 320px;

    outline: none;
}

.form__textarea::placeholder,
.form__input::placeholder{
    color: rgba(180, 180, 180, 1);
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
}

.form__textarea.error::placeholder,
.form__input.error::placeholder{
    color: #ffaca3 !important;
}

.form__textarea.disabled {
    background: var(--neutral-grey-100);
    cursor: not-allowed;
    color: var(--neutral-grey-500);
}

/* DESCRIPTION */
.form-section__row__col.description{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
}

/* PRICING */
.form__col__gap{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 80px;
    align-self: stretch;
}

.pricing__container,
.extras__container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    width: 100%;

    margin-top: 50px;
}

.pricing__col,
.extras__col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 100%;
}

.pricing__col_section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    width: 100%;

    padding: 12px;

    border-top: 1px solid var(--neutral-grey-300);
}

.pricing__col_section:first-child{
    border-top: 0px solid var(--neutral-grey-300);
}

.pricing__col_item{
    border-radius: 12px;
    border: 1px solid var(--neutral-grey-300);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

    width: 100%;
}

.pricing__container_col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
}

.pricing__row__bottom__col{
    cursor: pointer;
}

.pricing__row__bottom__col.disabled{
    cursor: not-allowed;
    opacity: 0.5;
}

.pricing__row__bottom__col.disabled .bottom__icon{
    background: var(--neutral-grey-100);
    border: 1px solid var(--neutral-grey-300);
}



.pricing__row,
.extras__row{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 24px;
}

.pricing__row.grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 16px;
    grid-gap: 12px;
}

.pricing__col_grid{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.pricing__row .icon-btn img,
.extras__row .icon-btn img{
    width: 20px;
    height: 20px;
}

.pricing__col_item .justify{
    justify-content: flex-start;
    gap: 10px;
}

.pricing__col_item .justify .form__col{
    width: 300px;
}

.gap-30{
    gap: 30px;
}

.pricing__row__bottom{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    width: 100%;

    padding: 12px;
    background: #FDFDFD;
    border-top: 1px solid var(--neutral-grey-200);

    border-radius: 0px 0px 12px 12px;
}

.pricing__row__bottom__col{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 18px;
    width: 100%;
}

.bottom__icon{
    display: flex;
    width: 40px;
    height: 40px;
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 8px;
    border: 1px solid var(--neutral-grey-200);
    background: var(--secondary-white);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}


.pricing__row__checkbox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid var(--neutral-grey-300);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.pricing__row__checkbox.disabled{
    cursor: not-allowed;
    background: var(--neutral-grey-100);
}

.pricing__row__checkbox.disabled label{
    color: var(--neutral-grey-500);
}

.pricing__row__checkbox input[type="checkbox"]{
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.pricing__row__checkbox label{
    color: var(--neutral-grey-700);
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

}

.pricing__row__checkbox.active{
    background: var(--primary-yellow);
}

/* SCHEDULE */

.form__col.radio{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}

.form__col.radio.no-gap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.form__row.radio{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}
.form__col__radio{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
}

.form__row__radio{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 100%;

}

.form__row__radio label{
    /* Text sm/Medium */
    font-family: Poppins, sans-serif;
    font-size: 15px!important;
    font-style: normal;
    font-weight: 400;

    width: auto;

}

.form__signin__row .form__col__radio{
    display: flex!important;
}
.form__signin__row .form__col.radio{
    width: 100%;
}

.form__signin__row .form__col.radio input[type="radio"]{
    width: 30px;
}

.user_signin .form__row.radio{
    align-items: center;
}

.user_signin .form__col.radio input[type="radio"]{
    width: 18px;
}

.user_signin .form__col.radio label{
    line-height: 10px;
}

.user_signin .form__col.radio p{
    display: none;
}



.form__col.radio input[type="radio"]{
    appearance: none;
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid var(--neutral-grey-300);
    border-radius: 50%; /* Make it a circle */
    background-color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 4px;
}

.form__col.radio input[type="radio"]:checked{
    border: 1px solid var(--primary-yellow);
}

.form__col.radio input[type="radio"]:checked::before{
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 50%; /* Make it a circle */
    background-color: var(--primary-yellow);
}

.schedule_gap{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 60px;
    width: 100%;
}

.schedule{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
}

.schedule-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.form__row.schedule input[type="checkbox"],
.form__row.checkbox input[type="checkbox"],
.modal__content__row input[type="checkbox"],
.form__col input[type="checkbox"]{
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    width: 18px;
    height: 18px;
    padding: 1.5px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--neutral-grey-400);
    cursor: pointer;

    transition: all 0.2s ease-in-out;
}

.form__row.schedule input[type="checkbox"]:checked,
.form__row.checkbox input[type="checkbox"]:checked,
.modal__content__row input[type="checkbox"]:checked,
.form__col input[type="checkbox"]:checked{
    border: 1px solid var(--primary-yellow);
    background: var(--warning-yellow-50);
    transition: all 0.2s ease-in-out;
}

.form__row.schedule input[type="checkbox"]:checked:before,
.form__row.checkbox input[type="checkbox"]:checked:before,
.modal__content__row input[type="checkbox"]:checked:before,
.form__col input[type="checkbox"]:checked:before{
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 1px;
    background: url('../../../assets/icones/global/check_stroke.svg') no-repeat center/cover;
}

.day-hours{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.day-col{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.day-header{
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 13px;
}

.form__row.checkbox label,
.form__col.checkbox label{
    font-size: 16px;
    line-height: 15px;
}

.day-header p,
.day-header label{
    color: #3C4043;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.1px;
}

.day-header_row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.day-hours input{
    display: flex;
    min-width: 120px;
    width: 100%;
    max-width: 115px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border: 0.75px solid #80868B;
    border-radius: 4px;

    color: #3C4043;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1px;
}

.hour{
    position: relative;
}

.hour.open::before{
    content: 'Ouverture';
    background: var(--secondary-white);
    color: #5F6368;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.4px; /* 153.333% */
    letter-spacing: 0.1px;

    padding: 5px 8px;
    top: -13px;
    left: 10px;
    position: absolute;
}

.hour.close::before{
    content: 'Fermeture';
    background: var(--secondary-white);
    color: #5F6368;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.4px; /* 153.333% */
    letter-spacing: 0.1px;

    padding: 5px 8px;
    top: -13px;
    left: 10px;
    position: absolute;
}

.dropdown-options{
    position: absolute;
    bottom: -210px;
    left: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: var(--secondary-white);
    border: 1px solid var(--neutral-grey-400);
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    z-index: 100;
    overflow-y: auto;
    max-height: 200px;
    font-size: 16px;
}

.dropdown-options .dropdown-option{
    width: 100%;
    padding: 15px 20px;
    border-top: 1px solid var(--neutral-grey-200);
    cursor: pointer;
}
.dropdown-options .dropdown-option:hover{
    background: var(--neutral-grey-50);
}
/* FORM DISABLED */
.is__disabled .button{
    display: none;
}

.is__disabled input{
    background: var(--neutral-grey-100);
    border: 1px solid var(--neutral-grey-300);
    cursor: not-allowed;

    color: var(--neutral-grey-500)!important;
}

.is__disabled input::placeholder{
    color: var(--neutral-grey-500);
}


/* REQUIRED */
.is__required{
    color: var(--warning-yellow-900);
}

/* GRID */
.form-section__row__col.formInfos.grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    grid-gap: 40px;
}


.grid .form__row__label, .row__input > label{
    max-width: 250px;
    min-width: 250px;
}

.grid_more{
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
    width: 100%;
}
.grid_more .column .form__col,
.grid_more .column label,
.grid_more select{
    min-width: 180px;
    max-width: 180px;
}

.text-grid{
    width: 100%;
}

.text-grid .column .form__col,
.text-grid .column label,
.text-grid select{
    max-width: 100%!important;
    min-width: 100%!important;
}


/* CONFIDENTIALITY */
.form__row__input__container__radio{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    gap: 15px;
    width: 100%;
    height: 100%;

    margin-bottom: 20px;
}
.form__row__input__container__radio .form__row{
    width: 100%!important;
}

.form__row__input__container__radio.form__col.radio input[type="radio"]{
    width: 35px!important;
}
.form__row__input__container__radio input{
    curosr: pointer;
}

.form__row__input__container__radio input{
    margin-top: 3px;
    curosr: pointer;
}

.form__row__input__container__radio label{
    color: var(--neutral-grey-500);

    /* Text sm/Medium */
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* MAPBOX */
mapbox-address-autofill{
    width: 100%;
}

.form__col.address.isRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    width: 100%;
}

.form__col.address.row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    width: 80%;
}

.access-container.col{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 80%;
}

.row .access-container .input{
    max-width: 162px!important;
}

.form__col__input__container__radio{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    gap: 8px;
    width: 100%;
    height: 100%;

    margin-bottom: 20px;
}

.form__col__input__container__radio .text-14{
    color: var(--neutral-grey-400);

    /* Text sm/Medium */
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight:300;
    line-height: normal;
}


.form__col__input__container__radio label{
    color: var(--neutral-grey-700)!important;
}

.form__col__input__container__radio .form__col.radio,
.form__col__input__container__radio .form__row.radio{
    width: 100%;
}


.form__row__icon__eye{
    cursor: pointer;
    width: 20px;
}

.form_group_tagContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
}


.form__group__tag{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    width: 100%;

    padding-bottom: 12px;
}

.form__group__tag__icone{
    width: 15px;
    cursor: pointer;
}

.form__group__tag__item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    border-radius: 8px;
    background: var(--neutral-grey-100);
    color: var(--neutral-grey-600);
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    width: auto;
}

.wrap {
    flex-wrap: wrap;
}

@media screen and (max-width: 991px){
    /* MAIL VERIFICATION */
    .input-code{
        width: 60px;
    }
}