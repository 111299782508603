select{
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    min-width: 250px;

    border-radius: 8px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;

    -webkit-appearance: none; /* For Chrome & Safari */
    -moz-appearance: none;    /* For old versions of Firefox */
    appearance: none;         /* Standard syntax */

    /* Adding custom arrow */
    background-image: url('../../../assets/icones/arrows/down_chevron.svg');
    background-repeat: no-repeat;
    background-position: right 10px center; /* adjust as necessary */
    background-size: 15px 15px; /* Size of the background image */
}

select.error{
    border: 1px solid var(--error-red-100);
}

select::placeholder{
    color: var(--neutral-grey-300);
}

select option:first-child{
    color: var(--neutral-grey-300);
}

select.error::placeholder{
    color: var(--error-red-100);
}

select[disabled]{
    background: var(--neutral-grey-200);
    border: 1px solid var(--neutral-grey-300);
    color: var(--neutral-grey-700);
    cursor: not-allowed;
}




/* DROPdown custom */

.select-dropdown-container{
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 10px;

    margin-top: 10px;
    width: 100%;
}

.select-dropdown-label{
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--primary-sea);
}

.select-dropdown-input{
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    min-width: 250px;
    width: 100%;

    border-radius: 8px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;

    -webkit-appearance: none; /* For Chrome & Safari */
    -moz-appearance: none;    /* For old versions of Firefox */
    appearance: none;         /* Standard syntax */

    /* Adding custom arrow */
    background-image: url('../../../assets/icones/arrows/down_chevron.svg');
    background-repeat: no-repeat;
    background-position: right 10px center; /* adjust as necessary */
    background-size: 15px 15px; /* Size of the background image */

    font-size: 14px;
    font-weight: 400;
    color: var(--neutral-grey-400);
}

.select-dropdown-list {
   position: absolute;
   top: 100%;
   left: 0;
   right: 0;

   background-color: #fff;
   z-index: 999;
    margin-top: 10px;


    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    max-height: 220px;
    min-width: 250px;

    border-radius: 12px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);

}
.select-dropdown-item {
   display: flex;
   align-items: center;
    border-bottom: 1px solid var(--neutral-grey-300);
    width: 100%;
    padding: 15px 14px;
}
.select-dropdown-item:last-child {
    border-bottom: none;
}

.select-dropdown-item label {
    margin: 0;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    color: var(--neutral-grey-700);
}


.select-dropdown-item input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    width: 18px;
    height: 18px;
    padding: 1.5px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--neutral-grey-400);
    cursor: pointer;

    transition: all 0.2s ease-in-out;
}

.select-dropdown-item input[type="checkbox"]:checked {
    border: 1px solid var(--primary-yellow);
    background: var(--warning-yellow-50);
    transition: all 0.2s ease-in-out;
}

.select-dropdown-item input[type="checkbox"]:checked::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 1px;
    background: url('../../../assets/icones/global/check_stroke.svg') no-repeat center/cover;
}

.select-dropdown-selected{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 10px;

    width: 100%;
}

.select-dropdown-selected-item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 4px 12px;
    border-radius: 8px;
    background: var(--neutral-grey-100);
    color: var(--neutral-grey-600);
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    width: auto;
}