/* -----  ROUND STAT ----- */
.round-stat{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    border: 15px solid var(--primary-yellow);
    border-radius: 50%;
    width: 190px;
    height: 190px;
}

.round-stat__value{
    color: var(--typography-black);
    text-align: center;
    font-family: RedHatDisplay, sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 700; /* 0% */
}
.round-stat__title{
    color: var(--typography-black);
    text-align: center;
    font-family: RedHatDisplay, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: lighter /* 0% */
}


/* -----  CARD STAT ----- */
.card-stat{
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    border-radius: 10px;
    border: 1px solid var(--border-grey, #E5E5E5);
    background: var(--secondary-white, #FFFFFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    position: relative;
}

.card-stat__title{
    color: var(--neutral-grey-600);
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.card-stat__value{
    font-family: Poppins, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
}

.card-stat .dot-container {
    position: absolute;
    top: 20px;
    right: 10px;
}