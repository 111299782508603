.calendar-section{
    position: relative;
    width: 100%;
    height: 100%;
}

.calendar-container{
    min-width: 350px;
    max-width: 100%;
    position: absolute;
    left: 0px;
    top: 90px;
    z-index: 100;

    border-radius: 8px;
    border: 1px solid var(--neutral-grey-100);
    background: var(--secondary-white);
    font-family: Poppins, sans-serif;

    /* Shadow/xl */
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}

.calendar__input{
    cursor: pointer;
}
.calendar__input input{
    cursor: pointer;
    background: none;
}

.is__disabled .calendar__input input{
    background: none;
    border: none;
}

.calendar__input input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
}

.is__disabled .calendar__input .form__row__input{
    background: var(--neutral-grey-100);
    border: 1px solid var(--neutral-grey-300);
    cursor: not-allowed;

    color: var(--neutral-grey-400);
}
.is__disabled .calendar__input .form__row__input img{
    opacity: 0.5;
}

.calendar__open{
    display: block;
}

.calendar__close{
    display: none;
}

.react-calendar{
    padding: 20px;
}

.calendar__buttons{
    display: flex;
    padding: 16px;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-top: 1px solid var(--neutral-grey-200);
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}
.react-calendar__navigation__label{
    color: var(--neutral-grey-700);
    text-align: center;

    /* Text md/Semibold */
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button{
    font-size: 0px;
    background-image: url('../../../assets/icones/arrows/left_chevron.svg');
    background-size: 15px;
    background-position: center;
    background-repeat: no-repeat;
}
.react-calendar__navigation__arrow.react-calendar__navigation__next-button{
    font-size: 0px;
    background-image: url('../../../assets/icones/arrows/right_chevron.svg');
    background-size: 15px;
    background-position: center;
    background-repeat: no-repeat;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
   display: none;
}


.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday{
    display: flex;
    width: 40px;
    height: 40px;
    padding: 10px 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.react-calendar__month-view__weekdays__weekday abbr {
    color: var(--neutral-grey-700)!important;
    text-align: center;

    /* Text sm/Regular */
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    text-decoration: none;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}

.react-calendar__month-view__days__day{
    display: flex;
    width: 40px;
    height: 40px;
    padding: 10px 8px;
    justify-content: center;
    align-items: center;
}

.react-calendar__month-view__days__day abbr,
.react-calendar__month-view__days__day--neighboringMonth abbr{
    color: var(--neutral-grey-500)!important;
    text-align: center;

    /* Text sm/Regular */
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
    border-radius: 20px;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover{
    background-color: var(--neutral-grey-100);
}

.react-calendar__tile--now {
    border-radius: 20px;
    background: var(--primary-yellow);
}

.react-calendar__tile--now abbr{
    color: var(--primary-sea)!important;
    text-align: center;

    /* Text sm/Medium */
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}


.react-calendar__tile--now:enabled:hover{
    background-color: var(--neutral-grey-100);
}

.react-calendar__tile--hasActive {
    background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover{
    background: #a9d4ff;
}
.react-calendar__tile--active{
    position: relative;
}
.react-calendar__tile--active::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 3px;
    transform: translateX(-50%);
    background-color: var(--primary-yellow);
    border-radius: 50%;
    width: 5px;
    height: 5px;
}


.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: var(--neutral-grey-100);
}

.react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth abbr{
    color: var(--neutral-grey-300)!important;
    border-radius: 20px;
}


.container__date-picker.disabled{
    background: var(--neutral-grey-100);
    border: 1px solid var(--neutral-grey-300);
    cursor: not-allowed;

    color: var(--neutral-grey-400);
}

.container__date-picker.disabled .day{
    background: transparent;
}

.container__date-picker.disabled .day.selected,
.container__date-picker.disabled .day.start-date,
.container__date-picker.disabled .day.end-date{
    background: var(--primary-yellow);
}
