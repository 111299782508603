.actions__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.actions__row > .icon-btn{
  background-color: var(--error-red-80);
  border: none;
}

.actions__row > .icon-btn img{
  width: 20px;
  height: 20px;
  cursor: pointer;
}