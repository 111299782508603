.main-offer__container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    height: fit-content;

    border-radius: 21px;
    padding: 26px 24px;
    border: 1px solid var(--neutral-grey-300);
    background: var(--secondary-white);
}

.main-offer__container__row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.main-offer__help{
    display: flex;
    padding: 50px 60px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;

    border-top: 1px solid var(--neutral-grey-200);
}

.main-offer__help.grey{
    padding: 32px 0 0 0;
}

.main-offer__help__contact{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
}
.main-offer__help__subscription{
    display: flex;
    flex-direction: column;
    padding: 45px 16px ;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: #F9FAFB;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.main-offer__help__subscription .main-offer__help__contact{
    margin-top: 30px;
}


.offer .form-section__row:first-child{
    border-top: none;
    padding-top: 0;
}

.offer .row__actions .form__row__label{
    min-width: unset;
}

.is__padding_container{
    padding-top: 20px;
    padding-bottom: 100px;
}
